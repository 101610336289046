/* Inter */
@font-face {
    font-family: "Inter-SemiBold";
    src: url('./assets/font/Inter/Inter-SemiBold.otf');
}

@font-face {
    font-family: "Inter-Medium";
    src: url('./assets/font/Inter/Inter-Medium.otf');
}

@font-face {
    font-family: "Inter-Regular";
    src: url('./assets/font/Inter/Inter-Regular.otf');
}

/* Montserrat */
@font-face {
    font-family: "Montserrat-SemiBold";
    src: url('./assets/font/Montserrat/Montserrat-SemiBold.ttf');
}

/* IBM Plex Sans */
@font-face {
    font-family: "IBMPlexSans-Bold";
    src: url('./assets/font/IBMPlexSans/IBMPlexSans-Bold.ttf');
}

@font-face {
    font-family: "IBMPlexSans-ExtraLight";
    src: url('./assets/font/IBMPlexSans/IBMPlexSans-ExtraLight.ttf');
}

@font-face {
    font-family: "IBMPlexSans-Light";
    src: url('./assets/font/IBMPlexSans/IBMPlexSans-Light.ttf');
}

@font-face {
    font-family: "IBMPlexSans-Medium";
    src: url('./assets/font/IBMPlexSans/IBMPlexSans-Medium.ttf');
}

@font-face {
    font-family: "IBMPlexSans-Regular";
    src: url('./assets/font/IBMPlexSans/IBMPlexSans-Regular.ttf');
}

@font-face {
    font-family: "IBMPlexSans-SemiBold";
    src: url('./assets/font/IBMPlexSans/IBMPlexSans-SemiBold.ttf');
}

@font-face {
    font-family: "IBMPlexSans-Thin";
    src: url('./assets/font/IBMPlexSans/IBMPlexSans-Thin.ttf');
}

/* Quattrocento Sans */
@font-face {
    font-family: "QuattrocentoSans-Bold";
    src: url('./assets/font/QuattrocentoSans/QuattrocentoSans-Bold.ttf');
}

@font-face {
    font-family: "QuattrocentoSans-Regular";
    src: url('./assets/font/QuattrocentoSans/QuattrocentoSans-Regular.ttf');
}