/* nav */
.defaultNavClass,
.changeNavBg {
  width: 100%;
  padding: 0 6rem;
  height: 4rem;
  display: flex;
  justify-content: space-between;
}

.defaultNavClass {
  background-color: #0C2034;
  width: 100%;
}

.changeNavBg {
  background-color: #101421;
  width: 100%;
}

.header {
    display: flex;
    justify-content: space-between;
}

.logo {
  display: inline-block;
  background: url('../../assets/img/unnamed.png');
  height: 2rem;
  min-width: 12.5rem;
  background-repeat: no-repeat;
  background-size: 12.5rem 2rem;
  margin: auto 0
}

.itemList {
    color: white;
    margin: auto 0;
    font-family: "IBMPlexSans-Light";
    display: inline-block;
    font-size: 1.125rem;
    width:70rem;

    .item {
        color: white;
        white-space: nowrap;
        margin-left: 2.5rem;
        vertical-align: middle;
        display: inline-block;
        text-decoration: none;
        cursor: pointer;
    }
}

.language, .languageBlue {
    display: inline-block;
    background: url('../../assets/img/Language-w.svg');
    height: 1.25rem;
    width: 1.25rem;
    background-repeat: no-repeat;
    vertical-align: middle;
    .languageBlue{
        background: url('../../assets/img/Language.svg');
    }
}

.customDropdown {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-left: 0.375rem;
    
    select {
        height: 1.75rem;
        background-color: #ffffff;
        color: var(--text-color);
        line-height: 1.375rem;
        font-size: 1rem;
        font-family: "Inter-SemiBold";
        border: 0.125rem solid var(--text-color);
        margin: 0;
        border-radius: 0.3125rem;
    }
}

.menuList {
    background-color: #101421;
    position: absolute;
    inset: 0.625rem -1rem auto auto; 
    transform: translate(0px, 1.5rem);
    border-radius: 0;
    width: calc(100vw + 0.125rem);
    height: calc(100vh - 2.875rem);
    background-clip: border-box;
    font-family: "Inter-Medium";
    font-size: 1.125rem;
    line-height: 2;
    .item {
        padding: 0.25rem 1rem;
        
    }
    *{
        color: #ffffff;
    }
    option {
        color:var(--text-color);
    }
}

.mAuto0 {
    margin: auto 0;
}

.menu {
    display: none;
}

/* 1150px */
@media (max-width:71.875rem) {
    nav {
        height: 3rem;
        max-width: 100%;
        min-width: 14.375rem;
        padding: 0.75rem 1.25rem;
    }

    .logo {
        height: 1.5rem;
        width: 9.375rem;
        background-size: 9.375rem 1.5rem;
    }

    .menu {
        height: 1.375rem;
        width: 1.75rem;
        float: right;
    }

    .close {
        height: 1.375rem;
        width: 1.375rem;
        float: right;
        border:none;
    }

    .itemHide {
        display: none;
    }

    .menu {
        display: block;
    }
}

/* 1024px */
@media (max-width:64rem) {
    .logo {
        height: 1.75rem;
        min-width: 9.25rem;
        background-size: 9.375rem 1.75rem;
    }

    .defaultNavClass {
        padding: 0 1rem;
    }
}

/* 430px */
@media (max-width:26.875rem) {
    .changeNavBg{
        padding: 0 1rem;
    }
}